.warrantyDisclaimer {
  display: flex;
  justify-content: center;
  padding: 0 120px;
  @media screen and (max-width: 680px) {
    padding: 0 20px;
  }
}
#form-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 65px;
  @media screen and (max-width: 670px) {
    flex-direction: column;
  }
  .step {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding: 10px 40px;
    &:last-child {
    }
    .count {
      width: 50px;
      height: 50px;
      background-color: #d7d9dd;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
    }

    p {
      color: #8f96a3;
      font-size: 18px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 3.6px;
      margin-bottom: 0px;
      margin-top: 0px;
      margin-left: 28px;
    }

    &.current {
      .count {
        background-color: #5ca0e6;
      }

      p {
        color: #5ca0e6;
      }
    }
  }
}

.topMargin10 {
  margin-top: 2rem;
}
.form-fields {
  &.centered {
    text-align: center;
  }
}

.deposit-total {
  margin-bottom: 20px;
}

#form {
  .container {
    width: 1300px;
  }
  #warranty-form {
    h2 {
      margin-bottom: 20px;
      font-size: 45px;
    }
    .form-fields {
      display: flex;
      flex-wrap: wrap;
      &.center {
        text-align: center;
      }
      .input-holder {
        position: relative;
        flex: 1 0 45%;
        &:nth-child(odd) {
          margin-right: 31px;
        }

        i {
          position: absolute;
          top: 14px;
          left: -2px;
          z-index: 9;
          color: #96d3ff;
          font-size: 24px;
          font-weight: 400;
          letter-spacing: -0.6px;
        }

        svg {
          fill: #96d3ff;
          height: 24px;
          position: absolute;
          top: 14px;
          left: 0px;
          z-index: 9;
        }

        .error_message {
          // display: none;
          color: #d35e5e;
          margin-bottom: 9px;
          width: 100%;
          float: left;
          &.show {
            display: initial;
          }
        }
      }
      input,
      select {
        margin-bottom: 34px;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-bottom: solid 2px #e3e9f6;
        opacity: 0.6;
        color: #384660;
        font-size: 22px;
        font-weight: 400;
        letter-spacing: -0.22px;
        padding-left: 53px;
        padding-bottom: 19px;
        outline-width: 0;
        width: 80%;
        &.no-icon {
          padding-left: 0px;
        }
      }

      select {
        height: 75px;
        background: transparent;
        text-indent: 43px;
        border-radius: 0px;
        margin-top: -10px;
        &.no-icon {
          text-indent: 0px;
        }
      }
    }

    .form-button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 236px;
      button {
        width: 399px;
        border: 0px;
        box-shadow: 0 20px 57px rgba(218, 137, 36, 0.21);
        border-radius: 10px;
        background-color: #f8901d;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%);
        color: #ffffff;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 4px;
        padding: 35px 20px;
      }
    }
  }
}

#file-upload {
  border-radius: 10px;
  border: 2px dashed rgba(1, 1, 1, 0.1);
  background-color: rgba(215, 217, 221, 0.1);
  text-align: center;
  > div {
    padding: 50px 340px;
  }

  h3 {
    color: #406183;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.12px;
  }

  p {
    opacity: 0.7;
    color: #406183;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.09px;
  }

  .button {
    box-shadow: 0 20px 57px rgba(61, 92, 124, 0.14);
    border-radius: 10px;
    background-color: #86a0ba;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.07) 0%, rgba(255, 255, 255, 0.07) 100%);
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    border: 0px;
    padding: 10px;
    cursor: pointer;
  }
}

#warrantyUploadpreview {
  .dz-preview.dz-image-preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    .dz-details {
      width: 65px;
      overflow: hidden;
      border-radius: 10px;
    }
    .dz-filename {
      margin-right: auto;
      margin-left: 10px;
    }

    .remove {
      cursor: pointer;
    }
  }
}

#uploadpreview {
  display: flex;
  flex-direction: column;
  .dz-preview {
    border: solid 3px #5ca0e6;
    padding: 10px;
    border-radius: 20px;
    overflow: hidden;
    margin-right: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    .dz-details {
      width: 40px;
    }

    span {
      &.remove {
        font-weight: 900;
        cursor: pointer;
        margin-left: auto;
        margin-right: 20px;
      }
    }

    .dz-filename {
      margin-left: 20px;
    }
  }
}

.dz-remove {
  margin-left: auto;
}

.fl {
  float: left;
}

.no-cursor {
  cursor: default !important;
}

.warranty-hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.issues {
  &.new {
    width: 100%;
    float: left;
    font-family: "Quicksand", sans-serif;
    background: #f4f4f4;
    padding: 0px;
    margin-bottom: 20px;
    .issue-contain {
      background: #f4f4f4;
    }

    .agent-image-holder {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
    }

    .issue-type {
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 15px;
    }

    .issue-description {
      padding-right: 20px;
      padding-left: 20px;
    }

    .banner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(45deg, #23dcde 30%, #45bfee 90%);
      text-transform: uppercase;
      padding-right: 20px;
      padding-left: 20px;
    }

    .MuiOutlinedInput-input,
    .MuiOutlinedInput-multiline {
      background: #fff;
      font-family: "Quicksand", sans-serif;
      font-size: 21px;
    }

    .MuiButton-label {
      font-size: 25px;
    }

    .MuiButton-fullWidth {
      height: 66px;
    }

    .upload {
      .attach {
        display: flex;
        align-items: center;
      }
    }
  }
  h1 {
    text-shadow: none;
    font-size: 40px;
  }
  .left {
    width: 500px;
  }
  .center {
    margin: auto;
  }
  .right {
    flex: 1;
    margin-left: 20px;
    .input-holder {
      margin-right: 0px !important;
      label {
        margin-right: 10px;
        margin-bottom: 10px;
        vertical-align: top;
      }
      textarea {
        border-radius: 10px;
        border: 2px solid rgba(14, 18, 32, 0.15);
        font-size: 17px;
        width: 50%;
        padding: 10px;
        // background-color: #dddddd;
      }
    }
  }
}

.next-btn {
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

.hidden {
  display: none;
  &.show {
    display: block;
  }
}

.warranty-upload-btn {
  width: 226px;
  height: 57px;
  border-radius: 10px;
  background-color: #86a0ba;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.8px;
  border: 0px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.issue-upload {
  .dz-preview {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .dz-details {
    width: 70px;
    margin-right: 10px;
  }

  .dz-filename {
    width: 270px;
  }
}

.radio {
  margin: auto;
  min-width: 25%;
  display: inline-block;
  @media screen and (max-width: 680px) {
    width: 50%;
  }
  label {
    margin: auto;
    min-width: 25%;
    display: inline-block;
    padding: 1rem 2rem;
    border: 2px solid #d7d9dd;
    border-radius: 5px;
    @media screen and (max-width: 680px) {
      padding: 0.5rem 1.5rem;
    }
  }
  input {
    margin-right: 10px;
    -webkit-appearance: auto;
  }
}

input,
select {
  &.error {
    border-bottom: solid 2px #ff0000 !important;
    margin-bottom: 0px !important;
  }
}

textarea {
  &.error {
    border: solid 2px #ff0000 !important;
    margin-bottom: 0px;
  }
}

.paypal-status-message {
  display: block;
}
.paypal-status-message.none {
  display: none;
}
.step4 {
  text-align: center;
}

.new-warranty-form {
  border: solid 1px #c7c7c7;
  border-radius: 8px;
  background: #f4f4f4;
  padding: 15px 20px;
  font-family: "Quicksand", sans-serif;
  .MuiInputBase-input.MuiOutlinedInput-input {
    background: #fff;
  }

  .continue-btn {
    padding: 17px 5px;
    font-size: 33px;
    color: #fff;
    display: table;
    border-radius: 4px;
    background-color: #46bfee;
    background: linear-gradient(90deg, #46bfee, #29dce0);
    background-image: -ms-linear-gradient(90deg, #46bfee 0, #29dce0 100%);
    transition: all 0.2s;
    text-decoration: none;
    font-weight: 600;
    width: 40%;
    box-shadow: 3px 3px 4px #3e3e3e !important;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
  }

  #file-upload {
    &.error {
      border-color: red !important;
    }

    h3 {
      font-size: 21px;
      font-weight: 200;
      color: #676767;
    }

    > div {
      padding: 0px 10px;
      background: transparent;
      border-radius: 8px;
    }

    .instructions {
      // background: #fff;
      border-radius: 8px;
      width: max-content;
      &.error {
        h3 {
          color: red !important;
        }
      }
    }
    .text-and-button-holder {
      display: flex;
      align-items: center;
    }
    .new-upload-button {
      font-family: "Quicksand", sans-serif;
      text-transform: none;
      background: white;
      border-radius: 0px;
      width: -moz-fit-content;
      width: fit-content;
      color: rgba(0, 0, 0, 0.5);
      border: solid 1px rgba(0, 0, 0, 0.5);
      /* font-weight: 100; */
      margin-left: auto;
      font-size: 23px;
      padding: 14px 12px;
      letter-spacing: 0rem;
      border-radius: 8px;
      box-shadow: 3px 3px 4px #3e3e3e;
    }
  }

  h3 {
    font-size: 2.5rem;
    color: #676767;
  }
}

.above-warranty-form-holder {
  .above-warranty-form {
    @media (min-width: 900px) {
      padding-left: 0px;
      padding-right: 0px;
    }

    .questions {
      margin-bottom: 30px;
      .question {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        position: relative;
        // transition: all 0.4s ease;
        font-weight: bold;
        color: #676767;
        font-family: "Quicksand", sans-serif;
        cursor: pointer;
        &:hover {
          background-color: #46bfee;
          background: linear-gradient(90deg, #46bfee, #29dce0);
          background-image: -ms-linear-gradient(90deg, #46bfee 0, #29dce0 100%);
          color: #fff;
        }
      }
    }
  }
}

.warranty-modal {
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .MuiDialog-paperFullWidth{
    max-height: calc(100% - 134px) !important;
    margin-top: -30px;
  }

  .MuiDialogTitle-root {
    color: #fff;
    background: linear-gradient(to left, #46bfee 0%, #22dedd 100%);
    text-align: center;
    background-color: #45bfee;
    margin-bottom: 20px;

    

    i {
      text-shadow: none;
      cursor: pointer;
    }

    h2 {
      font-size: 1.5em;
      line-height: 1.5em;
      text-shadow: none;
      color: #fff;
      font-weight: bold;
      margin-bottom: 0px;
    }

    h4 {
      font-size: 1.25rem;
      text-shadow: none;
      margin-top: 0px;
      font-weight: 100;
    }
  }
}
.issues {
  .text-and-button-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  > div {
    // padding: 0px 10px;
    background: transparent;
    border-radius: 8px;
  }
  h1 {
    color: white;
  }
  .banner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: linear-gradient(45deg, rgb(35, 220, 222) 30%, rgb(69, 191, 238) 90%);
    text-transform: uppercase;
    h3 : {
      font-size: 40px;
    }
    img {
      height: 60px;
      width: 60px;
    }
  }
  .upload {
    border-radius: 10px;
    border: 2px dashed rgba(1, 1, 1, 0.1);
    background-color: rgba(215, 217, 221, 0.1);
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .w-100 {
      width: 100%;
    }
    &.error {
      border-color: red;
      h3 {
        color: red;
      }
    }
    h3 {
      margin-left: 10px;
      font-size: 21px;
      font-weight: 200;
    }
  }
  .files {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    flex-direction: column;
    list-style: inherit;
    align-items: flex-end;
    padding-left: 40px;
    padding-right: 40px;
  }
  .new-upload-button {
    font-family: "Quicksand", sans-serif;
    text-transform: none;
    background: white;
    border-radius: 0px;
    width: -moz-fit-content;
    width: fit-content;
    color: rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(0, 0, 0, 0.5);
    /* font-weight: 100; */
    margin-left: auto;
    font-size: 23px;
    padding: 14px 12px;
    letter-spacing: 0rem;
    border-radius: 8px;
    box-shadow: 3px 3px 4px #3e3e3e;
  }
}
.accordion {
  padding: 10px;
  &.active {
    background-color: #e6f7fd;
  }
  .summary {
    width: 100%;
    .description {
      color: #545454;
    }
  }
}

@media only screen and (max-width: 500px) {
  #file-upload {
    > div {
      padding: 0;
    }
  }
  .new-warranty-form {
    .continue-btn {
      width: 96% !important;
    }
    h3 {
      font-size: 1.4rem !important;
    }
  }
  .instructions {
    h3 {
      font-size: 14px !important;
    }
  }
  .new-upload-button {
    font-size: 14px !important;
    padding: 4px !important;
  }
  .issues {
    h1 {
      font-size: 22px;
    }
    .attach {
      width: 100%;
    }
  }
}

.warranty-banner {
  #inner-banner {
    background-image: none;
    background-color: #ebedec;
    min-height: 350px;
    display: flex;
    align-items: center;
    .inner-banner-content {
      flex: 1;
      h1 {
        font-size: 65px;
        color: #313131;
        font-weight: 300;
        font-family: 'Quicksand', sans-serif;
        text-shadow: 3.993px 3.009px 2px rgba(216, 216, 216, .9);
        text-align: left;
        margin-bottom: 20px;
      }

      p {
        padding: 0 0 35px;
        font-size: 30px;
        line-height: 40px;
        color: #41c4eb;
        font-family: "Quicksand",serif;
        font-style: italic;
        font-weight: 400;
        position: relative;
        margin-top: -10px;
        text-align: left;
      }
    }
  }
}