.about-section-2 {
  position: relative;
  padding: 40px 0 90px 0;
  text-align: center;
  z-index: 1;
}
.listCont{
  li{
    margin-bottom: 20px;
    display: flex;
  }
  b{
    width: 120px;
  }
  span{
    width: 100%;
  }
}
.container.maximize{
  h2 {
    font-size: 3em;
  }
}
.about-section-2:before {
  content: "";
  width: 570px;
  height: 240px;
  background: url(../../Assets/images/grid.png) repeat 0 0;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  @media screen and (max-width: 989px) {
    top: 10px;
    height: 100px;
    width: 100%;
  }
}

.about-section-2.reviews::before {
  content: none;
  display: none;
}

.about-section-2 .container {
  max-width: 980px;
  position: relative;
  z-index: 5;
}
.about-section-2 h2 {
  padding: 0px 0px 30px;
  @media screen and (max-width: 989px) {
    font-size: 39px;
    line-height: 34px;
    font-weight: 300;
  }
}

.about-section-2 h3 {
  font-family: "Quicksand", serif;
  font-size: 72px;
  line-height: 76px;
  color: #2f2f2f;
  font-weight: normal;
  padding: 0 0 30px 0;
}

.about-section-2 h3 span {
  font-size: 50px;
  line-height: 54px;
  display: block;
}

.about-section-2 p {
  padding: 0 0 20px 0;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.about-section-2 ul li {
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

@media only screen and (max-width: 1220px) {
  .about-section-2 {
    padding: 80px 0;
  }
  .about-section-2 h3 {
    font-size: 52px;
    line-height: 56px;
  }
  .about-section-2 p {
    font-size: 22px;
    line-height: 26px;
  }
  .about-section-2 ul li {
    font-size: 22px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 989px) {
  .about-section-2 {
    padding: 50px 10px;
  }
  .about-section-2 h3 {
    padding-bottom: 25px;
    font-size: 42px;
    line-height: 46px;
  }
  .about-section-2 h3 span {
    font-size: 22px;
    line-height: 26px;
  }
  .about-section-2 p {
    font-size: 18px;
    line-height: 22px;
  }

  .about-section-2 ul li {
    font-size: 18px;
    line-height: 22px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .container.maximize{
    h2 {
      font-size: 2em;
    }
  }
  .about-section-2 h3 {
    font-size: 32px;
    line-height: 36px;
  }
}
