.options-section {
  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (max-width: 1195px) {
      grid-template-columns: repeat( auto-fill, minmax(30%, 1fr) );
      grid-gap: 20px;
      display: grid;
    }

    @media only screen and (max-width: 916px) {
      grid-template-columns: repeat( auto-fill, minmax(45%, 1fr) );
    }

    @media only screen and (max-width: 628px) {
      grid-template-columns: repeat( auto-fill, minmax(100%, 1fr) );
    }
    .single-option {
      border: solid 2px #eeeeee;
      display: flex;
      width: 24%;
      margin-bottom: 14px;
      padding: 5px 20px;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      
      cursor: pointer;
      box-shadow: 0 1px 2px rgba(0,0,0,0);
      box-sizing: border-box;
      transition: box-shadow 0.2s ease-in-out;
      @media only screen and (max-width: 1195px) {
        width: initial;
        height: 85px;
        margin-bottom: 0px;
      }
      &.active {
        border: solid 2px #00aedd;
        i {
          color: #00aedd;
        }
      }

      &:hover {
        box-shadow: 0px 3px 25px rgba(0, 0, 0, 0.15);
      }

      /* Transition to showing the bigger shadow on hover */
      .make-it-fast:hover::after {
        opacity: 1;
      }
      h3 {
        font-size: 23px;
        margin-right: auto;
        margin-left: 15px;
      }

      i {
        color: #b7b7b7;
      }
    }
  }
}

i {
  &.green {
    color: #26aede !important;
  }
}

.finish-items {
  h3 {
    margin-bottom: 40px;
    margin-top: 30px;
    text-shadow: 4px 3px 2px #d8d8d8;
    color: #424242;
    font-family: "Quicksand", sans-serif;
    font-size: 55px;
    font-weight: 400;
    line-height: 72px;
  }
  .section-title {
    text-align: center;
    height: 145px;
    background-color: rgba(204, 204, 204, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    h2 {
      text-shadow: 4px 3px 2px #d8d8d8;
      color: #424242;
      font-family: "Quicksand", sans-serif;
      font-size: 72px;
      font-weight: 400;
      line-height: 72px;
    }
  }
  .finish-items-holder {
    grid-template-columns: repeat( auto-fill, minmax(24%, 1fr) );
    grid-gap: 20px;
    display: grid;
    padding-bottom: 60px;
    @media only screen and (max-width: 930px) {
      grid-template-columns: repeat( auto-fill, minmax(40%, 1fr) );
    }

    @media only screen and (max-width: 690px) {
      grid-template-columns: repeat( auto-fill, minmax(100%, 1fr) );
    }
    .single-finish {
      cursor: pointer;
      &.selected {
        position: relative;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -40px;
          margin-left: -40px;
          font-size: 80px;
          color: #26aede;          
        }
      }
      .image-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0 1px 2px rgba(0,0,0,0);
        box-sizing: border-box;
        transition: box-shadow 0.3s ease-in-out;
        border: solid 2px rgba(0, 0, 0, 0.08);
        position: relative;
        &:hover {
          box-shadow: 0px 5px 48px rgba(0, 0, 0, 0.94);
        }

        @media only screen and (max-width: 1000px) {
          &:hover {
            box-shadow: none;
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      h4 {
        margin-top: 8px;
        color: #000000;
        font-size: 19px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: 0.52px;
      }

      p {
        opacity: 0.5;
        color: #000000;
        font-size: 21px;
        font-weight: 400;
        line-height: 32.18px;
        letter-spacing: 0.08px;
      }
    }

    &.tiles {
      .single-finish {
        .image-holder {
          img {
            max-width: unset;
            max-height: unset;
          }
        }
      }
    }
  }
}

.floating-next {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 70px;
  background: #1d3c5a;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: #fff;
    margin-left: 20px;
  }
  button {
    color: white;
    font-size: 20px;
    background: #26aede;
    padding: 10px 40px;
    border-radius: 8px;
    margin-right: 20px;
  }
}

.no-bottom-marg {
  margin-bottom: 0px !important;
}