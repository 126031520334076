.container.vision {
    padding: 20px 10px 60px 10px;
  
    .believe{
      transform: rotate(-90deg);
      position: absolute;
      margin-top: 150px;
      margin-left: -150px;
    }
    .title{
      border-bottom: 2px solid #1d3c5a;
    }
    .par{
      font-size: 1.3em;
      max-width: 600px;
      margin-left: 320px;
      position: absolute;
      margin-top: -81px;
      color: #1d3c5a;
      span{
        color: #45c1ed;
        font-weight: 700;
      }
    }
    .list{
      padding-left: 10px;
      margin-top: 30px;
      padding-left: 80px;
      li {
        list-style-type: none;
        color: #1d3c5a;
  
        span{
          color: #45c1ed;
          font-weight: 700;
        }
      }
    }
}
  
@media only screen and (max-width: 1220px) {
    .container.vision {
      .believe{
        margin-top: 125px;
        margin-left: -110px;
      }
    }
}
  
@media only screen and (max-width: 989px) {
    .container.vision {
      padding: 20px 10px 20px 10px;
  
      .believe{
        transform: none;
        position: relative;
        padding: 0;
        padding-left: 60px;
        margin-top: 30px;
        text-align: left;
        font-size: 1.5em;
        width: 100%;
      }
  
      .par{
        max-width: 600px;
        margin-top: 10px;
        position: relative;
        margin-left: 0;
        text-align: center;
        font-size: 1em;
        line-height: 24px;
      }
  
      .list {
        padding-left: 10px;
        margin-top: 0px;
        padding-left: 0px;
      }
  
    }
}
  
