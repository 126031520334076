.services {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	cursor: pointer;
	height: 100%;
	padding: 0px 8px;
	&_btn {
		display: flex;
		width: auto;
		align-items: center;
		border: 1px solid #000;
		opacity: 0.8;
		border-radius: 50%;
		padding: 5px;
		margin-top: 8px;
		svg {
			width: 16px;
			height: 16px;
		}
	}
	&_label {
		margin-top: 6px;
	}
	&:hover {
		.services_btn {
			border: 1px solid #43c4e4;
			svg path {
				fill: #43c4e4;
			}
		}
		.services_label {
			color: #43c4e4;
		}
	}
}

.service {
	display: flex;
	align-items: center;
	min-width: 120px;
	color: #000;
	font-size: 16px;
	transition: 0.3s ease-out;
	cursor: pointer;
	background: #fff;
	margin: 4px 0px;
	padding: 0px 12px;
	white-space: nowrap;
	&_img {
		width: 26px;
		object-fit: cover;
		margin-right: 12px;
		filter: grayscale(1);
	}
	&_title {
		margin: 0;
	}
	&:hover {
		background: rgba(211,211,211,0.6);
	}
}

.break{
	height: 1;
	width: 70%;
	margin: 0 auto;
	opacity: 0.5;
	&:last-child {
		display: none;
	}
}